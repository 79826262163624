.react-autosuggest__container {
  position: relative;
  z-index: 101;
}
.react-autosuggest__suggestionsContainerOpen {
  position: absolute;
  z-index: 1;
  margin-top: 0;
  left: 0;
  right: 0;
}
.react-autosuggest__suggestionsList {
  margin: 0;
  padding: 0;
  z-index: 1;
  list-style-type: none;
  display: block !important;
  word-break: break-all;
  white-space: pre-wrap !important;
  max-height: 220;
  overflow: auto;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.react-autosuggest__searchResultList {
  background-color: #ffffff !important;
  border-bottom: 1px solid rgba(243, 244, 245, 1) !important;
  box-shadow: none;
  border-radius: 0;
}
