.itemStyle {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  width: 40px;
}
.activeItem {
  border: solid 1px #808e9b;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  color: #fff;
  background-color: #00446b;
}
.paginationContainer {
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: center;
  list-style-type: none;
  position: relative;
}
.disabledItemStyle {
  color: #d3d3d3;
}
.nextStyle {
  font-size: 4px;
  height: 60px;
  position: absolute;
  right: 0;
  width: 150px;
}
.paginationPage {
  font-weight: 700;
}
.previousStyle {
  font-size: 4px;
  height: 60px;
  left: 0;
  position: absolute;
  width: 150px;
}
